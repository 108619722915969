import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import jwtDecode from 'jwt-decode';
import { cloneDeep, isEqual } from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Auth0Client } from '@auth0/auth0-spa-js';
import { AuthContextData, AuthState, GetTemPermissaoFnOpcoes } from 'contexts';
import { Usuario, UsuarioTokenExterno } from 'models';
import { authZero } from 'services';
import { useAdquirirLotacao } from 'services/adquirir-lotacao';
import { servicoBase } from 'services/servico-base';
import { getHashedUrlTermos, verificarSeAceitouTermos } from 'services/termos';
import { parsedLocalStorage, validadorDePermissoes } from 'utils';
import { useAdquirirPermissoes } from 'services/usuario/adquirir-permissoes';
import { useAdquirirPontosDeAtendimentoPossiveis } from 'services/usuario/adquirir-pontos-atendimentos-possiveis';
import { PontoDeAtendimento } from 'models/areaDeAtendimento/ponto-de-atendimento';

export function useBloc() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [adquirirLotacao] = useAdquirirLotacao();
  const [adquirirPermissoes] = useAdquirirPermissoes();
  const [adquirirPontosDeAtendimento] =
    useAdquirirPontosDeAtendimentoPossiveis();

  const [loading, setLoading] = useState<boolean>(true);
  const [dispositivoId] = useState<string>(() => {
    const localDispositivoId = localStorage.getItem('user:device-id');

    if (localDispositivoId !== null) {
      return localDispositivoId;
    }

    const newdispositivoId = uuid();

    localStorage.setItem('user:device-id', newdispositivoId);

    return newdispositivoId;
  });

  const [data, setData] = useState<AuthState>(() => {
    const lotacao = parsedLocalStorage.getItem<number>('lotacao');
    const usuario = parsedLocalStorage.getItem<Usuario>('user');
    const token = localStorage.getItem('token');
    const papelZeroToken = localStorage.getItem('papel-zero-token');
    const termosUrl = localStorage.getItem('termos-url');
    const aceitouOsTermos =
      parsedLocalStorage.getItem<boolean>('aceitou-termos');

    const pontosAtendimentoPossiveis = parsedLocalStorage.getItem<
      PontoDeAtendimento[]
    >('pontosAtendimentosPossiveis');

    if (usuario && token) {
      servicoBase.defaults.headers.common.Authorization = `Bearer ${token}`;
      servicoBase.defaults.headers.common['X-Identificacao-Atendente'] =
        usuario.nickname;

      if (lotacao !== null) {
        servicoBase.defaults.headers.common['X-Lotacao-Id'] = lotacao;
      }
      return {
        usuario,
        token,
        pontosAtendimentoPossiveis: pontosAtendimentoPossiveis || undefined,
        aceitouOsTermos: aceitouOsTermos !== null ? aceitouOsTermos : false,
        ...(termosUrl !== null ? { termosUrl } : {}),
        ...(lotacao !== null ? { lotacao } : {}),
        ...(papelZeroToken ? { papelZeroToken } : {}),
      };
    }
    return {} as AuthState;
  });

  const dadosTokenExterno = useCallback(async () => {
    const tokenExterno = searchParams.get('token');
    let retorno = {} as UsuarioTokenExterno;
    if (tokenExterno) {
      const tokenDecodificado = jwtDecode<any>(tokenExterno);

      if (tokenDecodificado.exp < Date.now() / 1000) return retorno;

      const idAutenticado = tokenDecodificado.sub;
      const user = {} as Usuario;
      const response = await servicoBase.get<any>(
        `usuarios/autenticador/${idAutenticado}`,
      );

      user.email = response.data.email;
      user.name = response.data.nome;
      user.nickname = response.data.cpf;

      retorno = {
        user,
        token: tokenExterno,
      };
    }
    return retorno;
  }, [searchParams]);

  const deslogar = useCallback(async () => {
    try {
      const authZeroClient = await authZero();
      const storageddispositivoId = localStorage.getItem('user:device-id');
      localStorage.clear();

      if (storageddispositivoId) {
        localStorage.setItem('user:device-id', storageddispositivoId);
      }

      await authZeroClient
        .logout({
          returnTo: process.env.REACT_APP_AUTH_ZERO_REDIRECT_URI,
        })
        ?.then(() => {
          setData({} as AuthState);
          const loadingDiv = document.getElementById(
            'pre-loading',
          ) as HTMLDivElement;
          loadingDiv.setAttribute('style', 'display: none;');
        });
    } catch (error) {
      console.info({ error });
    }
  }, []);

  const mudarUsuario = useCallback((user: any) => {
    setData(oldState => ({
      ...oldState,
      user,
    }));

    parsedLocalStorage.setItem('user', user);
  }, []);

  const mudarToken = useCallback((newToken: string) => {
    setData(oldState => ({
      ...oldState,
      token: newToken,
    }));

    localStorage.setItem('token', newToken);
  }, []);

  const mudarPontosPossiveis = useCallback(
    (novosPontosDeAtendimentoPossiveis: PontoDeAtendimento[]) => {
      const pontosDeAtendimentoAtuais = parsedLocalStorage.getItem<
        PontoDeAtendimento[]
      >('pontosAtendimentosPossiveis');

      if (
        !isEqual(pontosDeAtendimentoAtuais, novosPontosDeAtendimentoPossiveis)
      ) {
        setData(oldState => ({
          ...oldState,
          pontosAtendimentoPossiveis: novosPontosDeAtendimentoPossiveis,
        }));

        parsedLocalStorage.setItem(
          'pontosAtendimentosPossiveis',
          novosPontosDeAtendimentoPossiveis,
        );
      }
    },
    [],
  );

  const podeAcessar = useCallback(
    (
      permissions: any[],
      options: GetTemPermissaoFnOpcoes = {} as GetTemPermissaoFnOpcoes,
    ) => {
      const { temTodasAsPermissoes, temQualquerPermissao } = options;

      return validadorDePermissoes({
        permissoesDeUsuario: (data?.usuario as Usuario)?.permissions || [],
        permissoesParaValidar: permissions,
        temTodasAsPermissoes,
        temQualquerPermissao,
      });
    },
    [data?.usuario],
  );

  const memoValue: AuthContextData = useMemo(() => {
    return {
      token: data?.token,
      usuario: data?.usuario,
      papelZeroToken: data.papelZeroToken,
      deslogar,
      mudarUsuario,
      mudarToken,
      mudarPontosPossiveis,
      loading,
      dispositivoId,
      podeAcessar,
      lotacao: data?.lotacao,
      aceitouOsTermos: data?.aceitouOsTermos,
      termosUrl: data?.termosUrl,
      pontosAtendimentoPossiveis: data?.pontosAtendimentoPossiveis,
    };
  }, [
    data?.pontosAtendimentoPossiveis,
    data?.token,
    data?.usuario,
    data.papelZeroToken,
    data?.lotacao,
    data?.aceitouOsTermos,
    data?.termosUrl,
    deslogar,
    mudarUsuario,
    mudarToken,
    mudarPontosPossiveis,
    loading,
    dispositivoId,
    podeAcessar,
  ]);

  const verificarSeEstaLogado = useCallback(async () => {
    const loadingDiv = document.getElementById('pre-loading') as HTMLDivElement;
    const authZeroClient = await authZero();
    const isAuthenticated = await authZeroClient.isAuthenticated();
    let lotacao = 0;
    let [aceitouOsTermos, termosUrl]: [boolean, string] = [
      data?.aceitouOsTermos || false,
      data?.termosUrl || '',
    ];

    if (isAuthenticated && !!data.usuario && !!data.token) {
      if (data?.usuario?.nickname) {
        const aceitouOsTermosFn = async () =>
          aceitouOsTermos
            ? Promise.resolve(aceitouOsTermos)
            : verificarSeAceitouTermos(data?.usuario?.nickname || '');
        const termosUrlFn = async () =>
          termosUrl
            ? Promise.resolve(termosUrl)
            : getHashedUrlTermos(data?.usuario?.nickname || '');
        [aceitouOsTermos, termosUrl] = await Promise.all([
          aceitouOsTermosFn(),
          termosUrlFn(),
        ]);

        servicoBase.defaults.headers.common['X-Identificacao-Atendente'] =
          data.usuario.nickname;
      }

      setData(oldState => ({
        ...oldState,
        ...(termosUrl ? { termosUrl } : {}),
        ...(aceitouOsTermos ? { aceitouOsTermos } : {}),
      }));

      parsedLocalStorage.setItem('aceitou-termos', aceitouOsTermos);
      parsedLocalStorage.setItem('termos-url', termosUrl);

      loadingDiv.setAttribute('style', 'display: none;');
      setTimeout(() => {
        setLoading(() => false);
      }, 1000);
      return;
    }

    let urlTokenExterno = {} as string | undefined;
    try {
      const tokenExterno = await dadosTokenExterno();
      let user = {} as Usuario | undefined;
      let token = {} as string | undefined;
      let papelZeroToken = {} as string | undefined;
      let pontosAtendimentoPossiveis = [] as PontoDeAtendimento[];

      if (Object.keys(tokenExterno).length === 0) {
        await authZeroClient.handleRedirectCallback();
        user = await authZeroClient.getUser<Usuario>();
        token = await authZeroClient.getTokenSilently();

        const newAuthZero = cloneDeep<Auth0Client>(authZeroClient);
        (newAuthZero as any).options.audience = 'papelzero';
        papelZeroToken = await newAuthZero.getTokenSilently();
      } else {
        urlTokenExterno = location.pathname;
        user = tokenExterno.user;
        token = tokenExterno.token;
        papelZeroToken = tokenExterno.token;
      }

      if (!token) {
        await deslogar();
        setTimeout(() => {
          setLoading(() => false);
        }, 1000);
        return;
      }

      servicoBase.defaults.headers.common.Authorization = `Bearer ${token}`;

      if (user) {
        const permissoes = await adquirirPermissoes();
        user = { ...user, permissions: permissoes };
      }

      if (user?.nickname) {
        const lotacaoApi = await adquirirLotacao(user?.nickname as string);
        servicoBase.defaults.headers.common['X-Identificacao-Atendente'] =
          user.nickname;

        if (lotacaoApi !== null) {
          lotacao = lotacaoApi;
        }
      }

      if (user && lotacao) {
        pontosAtendimentoPossiveis = await adquirirPontosDeAtendimento(lotacao);
        parsedLocalStorage.setItem(
          'pontosAtendimentosPossiveis',
          pontosAtendimentoPossiveis,
        );
      }

      parsedLocalStorage.setItem('user', user);
      parsedLocalStorage.setItem('lotacao', lotacao);
      localStorage.setItem('token', token);
      localStorage.setItem('papel-zero-token', papelZeroToken || '');
      servicoBase.defaults.headers.common['X-Lotacao-Id'] = lotacao as number;

      const aceitouOsTermosFn = async () =>
        aceitouOsTermos
          ? Promise.resolve(aceitouOsTermos)
          : verificarSeAceitouTermos(user?.nickname || '');
      const termosUrlFn = async () =>
        termosUrl
          ? Promise.resolve(termosUrl)
          : getHashedUrlTermos(user?.nickname || '');
      [aceitouOsTermos, termosUrl] = await Promise.all([
        aceitouOsTermosFn(),
        termosUrlFn(),
      ]);

      [aceitouOsTermos, termosUrl] = await Promise.all([
        aceitouOsTermosFn(),
        termosUrlFn(),
      ]);

      if (aceitouOsTermos !== null) {
        localStorage.setItem('aceitou-termos', String(aceitouOsTermos));
      }

      if (termosUrl !== null) {
        localStorage.setItem('termos-url', termosUrl);
      }

      setData(oldState => ({
        ...oldState,
        token,
        pontosAtendimentoPossiveis,
        ...(termosUrl ? { termosUrl } : {}),
        ...(aceitouOsTermos ? { aceitouOsTermos } : {}),
        ...(papelZeroToken ? { papelZeroToken } : {}),
        ...(lotacao !== null && lotacao !== undefined ? { lotacao } : {}),
        ...(user ? { usuario: user } : {}),
      }));
    } catch (error) {
      console.info('');
    } finally {
      if (urlTokenExterno) {
        navigate(urlTokenExterno);
      } else {
        navigate('/app/aplicativos');
      }
      setTimeout(() => {
        loadingDiv.setAttribute('style', 'display: none;');
        setLoading(() => false);
      }, 1000);
    }
  }, [
    adquirirPontosDeAtendimento,
    adquirirPermissoes,
    adquirirLotacao,
    data?.aceitouOsTermos,
    data?.termosUrl,
    data.token,
    data.usuario,
    deslogar,
    navigate,
    dadosTokenExterno,
    location.pathname,
  ]);

  useEffect(() => {
    if (!window.name) {
      window.name = uuid().replaceAll(/-/g, '');
    }
  }, []);

  useEffect(() => {
    window.deslogar = deslogar;
  }, [deslogar]);

  useLayoutEffect(() => {
    verificarSeEstaLogado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { memoValue, loading };
}
