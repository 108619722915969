import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { tratarRespostaExcecao } from 'utils/tratar-resposta-excecao';
import { PontoDeAtendimento } from 'models/areaDeAtendimento/ponto-de-atendimento';

export type UseAdquirirPontosDeAtendimentoPossiveis = [
  (unidadeId?: number) => Promise<PontoDeAtendimento[]>,
  { loading: boolean; erro: AxiosError | null },
];

export function useAdquirirPontosDeAtendimentoPossiveis(): UseAdquirirPontosDeAtendimentoPossiveis {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const adquirirPontosDeAtendimento = useCallback(
    async (unidadeId?: number) => {
      try {
        setLoading(true);

        const { data: result } = await servicoBase.get<
          PontoDeAtendimento[] | null
        >(`/areadeatendimento/pontosdeatendimento/porpermissao/${unidadeId}`);

        return result !== null ? result : [];
      } catch (error) {
        const err = error as AxiosError;
        const respostaExcecao = tratarRespostaExcecao(err);

        criarMensagem({
          titulo: 'Erro',
          texto: respostaExcecao.mensagem,
          tipo: 'erro',
        });

        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }

      return [];
    },
    [criarMensagem],
  );

  return [adquirirPontosDeAtendimento, { loading, erro }];
}
