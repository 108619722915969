type ParsedLocalStorage = Storage & {
  getItem<T = any>(key: string): T | null;
  setItem(key: string, data: any): void;
  removeAllExceptUserData(): void;
};

export const parsedLocalStorage: ParsedLocalStorage = {
  ...localStorage,
  getItem<T = any>(key: string): T | null {
    const data = localStorage.getItem(key);

    return data ? (JSON.parse(data) as T) : null;
  },
  setItem<T = any>(key: string, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
  },
  removeItem: localStorage.removeItem,
  removeItemFromPattern(pattern: string) {
    const { length: _length, ...rest } = localStorage;
    const userDeviceId = localStorage.getItem('user:device-id');

    Object.keys(rest)
      .filter(key => key.includes(pattern))
      .map(key => localStorage.removeItem(key));

    localStorage.setItem('user:device-id', userDeviceId as string);
  },
  removeAllExceptUserData() {
    const userDeviceId = localStorage.getItem('user:device-id');
    const lotacao = localStorage.getItem('lotacao');
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
    const aceitouOsTermos = localStorage.getItem('user:accept-terms');
    const papelZeroToken = localStorage.getItem('papel-zero-token');
    const termosUrl = localStorage.getItem('termos-url');
    const aceitouTermos = localStorage.getItem('aceitou-termos');
    const pontosAtendimentoPossiveis = localStorage.getItem(
      'pontosAtendimentosPossiveis',
    );
    const pontoAtendimentoSelecionado = localStorage.getItem(
      'pontoAtendimentoEscolhido',
    );
    const filtrarPendentes = localStorage.getItem(
      '@procon-ms:fila-atenmdimento:pendentes',
    );

    localStorage.clear();
    localStorage.setItem('user:device-id', userDeviceId as string);

    if (user) {
      localStorage.setItem('user', user);
    }

    if (token) {
      localStorage.setItem('token', token as string);
    }

    if (filtrarPendentes) {
      localStorage.setItem(
        '@procon-ms:fila-atenmdimento:pendentes',
        filtrarPendentes,
      );
    }

    if (aceitouOsTermos) {
      localStorage.setItem('user:accept-terms', aceitouOsTermos);
    }

    if (lotacao) {
      localStorage.setItem('lotacao', lotacao);
    }

    if (papelZeroToken) {
      localStorage.setItem('papel-zero-token', papelZeroToken);
    }

    if (termosUrl) {
      localStorage.setItem('termos-url', termosUrl);
    }

    if (aceitouTermos) {
      localStorage.setItem('aceitou-termos', aceitouTermos);
    }

    if (pontosAtendimentoPossiveis) {
      localStorage.setItem(
        'pontosAtendimentosPossiveis',
        pontosAtendimentoPossiveis,
      );
    }

    if (pontoAtendimentoSelecionado) {
      localStorage.setItem(
        'pontoAtendimentoEscolhido',
        pontoAtendimentoSelecionado,
      );
    }
  },
};

export function makeStorageKey(key: string): string {
  const { REACT_APP_STORAGE_PREFIX, REACT_APP_NODE_ENV } = process.env;

  return `${REACT_APP_STORAGE_PREFIX}:${REACT_APP_NODE_ENV}:${window.name}:${key}`;
}
